@media (max-width: 768px) {
    .swiper-wrapper {
        height: auto;
        padding-bottom: 5%;
    }

    .swiper-pagination {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .swiper-pagination-bullets.swiper-pagination-horizontal {
        bottom: 1%;
    }

    .testi-text {
        margin-top: 60px;
    }

    #results-swiper {
        width: 91%;
        margin-bottom: 36px;
        padding-bottom: 0;
    }

    .swiper-button-next {
        margin-left: 0;
    }

    .swiper-button-prev {
        margin-right: 0;
    }

    #testiSwiper {
        width: 88%;
        height: auto;
        border-radius: 8px;
        margin-bottom: 69px;
    }

    #testi-slide{
        height: auto;
    }

    #testi-slide img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: fill;
        width: 300px;
        border-radius: 8px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    }
}