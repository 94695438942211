.Btn {
    width: 45px;
    height: 45px;
    background: linear-gradient(#44ea76, #39fad7);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    cursor: pointer;
    position: fixed;
    bottom: 50px;
    right: 30px;
    border: none;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2); 
    z-index: 11;
}

.arrow path {
    fill: white;
}

.Btn:hover .text {
    opacity: 1;
    transition-duration: .7s;
}

.Btn:hover .arrow {
    animation: slide-in-bottom .7s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@keyframes slide-in-bottom {
    0% {
        transform: translateY(10px);
        opacity: 0;
    }

    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

@media (max-width: 480px) {
    .Btn{
        right: 7px;
    }
}