@media (max-width: 768px) {
    .free-section {
        padding: 60px 0;
    }

    .free-content {
        flex-direction: column;
        text-align: center;
    }

    .free-text {
        padding: 0;
        margin-top: 36px;
    }

    .free-bullet {
        margin: 24px 0;
    }

    .bullet-text span {
        text-align: left;
    }

    .gift-icon {
        text-align: start;
    }
}

@media (min-width: 481px) and (max-width: 768px) {}

@media (min-width: 769px) and (max-width: 1200px) {
    .free-section {
        padding: 60px 5%;
    }
}