.testi-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: var(--light-green-background);
    height: fit-content;
}

.testi-text {
    display: block;
    justify-content: start;
    align-items: center;
    width: 88%;
    margin-top: 80px;
}

#testiSwiper {
    width: 88%;
    height: 550px;
    border-radius: 8px;
    margin-bottom: 69px;
}

#testi-slide {
    text-align: center;
    font-size: 18px;
    background: transparent;

    /* testi-slide text vertically */
    display: flex;
    justify-content: center;
    align-items: baseline;
}

#testi-slide img {
    display: block;
    width: 100%;
    height: 95%;
    object-fit: fill;
    width: 350px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.swiper-button-next,
.swiper-button-prev {
    color: var(--dark-purple);
}

.swiper-button-next {
    margin-left: 10px;
}

.swiper-button-prev {
    margin-right: 10px;
}

#results-swiper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40%;
    border-radius: 8px;
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
    margin-bottom: 100px;
    padding-bottom: 2%;
}

#results-slide {
    text-align: center;
    font-size: 18px;
    background: transparent;

    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
}

#results-slide img {
    display: block;
    width: 100%;
    object-fit: fill;
}

.swiper-pagination-bullet-active {
    background-color: var(--dark-purple);
}