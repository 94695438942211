.product-section{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: var(--light-green-background);
    height: 110vh;
}

.product-text{
    display: block;
    justify-content: start;
    align-items: center;
    width: 88%;
}

.product-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: fit-content;
    width: 88%;
}

.product-card{
    display: block;
    justify-content: center;
    align-items: center;
    border-radius: 16px;
    background-color: var(--white-background);
    height: fit-content;
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2); 
    padding-bottom: 1%;
}

.product-img{
    width: 300px;
    height: 300px;
    border-radius: 16px 16px 0px 0px;
    object-fit: contain;
}

.product-name{
    margin: 0;
    margin-top: 1%;
    padding-left: 5%;
}

.product-function{
    margin-top: 1%;
    padding-left: 5%;
    color: var(--grey-text);
    font-size: 18px;
}

#oiru-gradient, #daitto-gradient{
    background: linear-gradient(to right, var(--dark-purple), var(--light-purple));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

#shuga-gradient, #mizu-gradient{
    background: linear-gradient(to right, var(--dark-green), var(--light-green));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}