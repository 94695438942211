/* General Styles */
body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
}

.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
}

/* hero Section Styles */
.hero-section {
    background-color: var(--white-background);
    padding: 80px 0;
}

.hero-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.hero-text {
    flex: 1;
    padding-right: 40px;
}

.wlc-msg{
    display: flex;
    justify-content: start;
    align-items: center;
    height: fit-content;
}

.wlc-msg h1 {
    font-size: 50px;
    margin-top: 0;
    margin-bottom: 0;
}

.hero-text p {
    font-size: 20px;
    margin-bottom: 30px;
    color: var(--grey-text);
    line-height: 1.6;
}

.cta-btn {
    background-color: var(--dark-green);
    color: #fff;
    padding: 12px 24px;
    border: none;
    border-radius: 8px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.cta-btn:hover {
    background-color: var(--dark-green-hover);
}

.hero-image {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
}

.hero-image img {
    max-width: 100%;
    height: auto;
    max-height: 500px;
    border-radius: 8px;
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2); 
}

.zenso-gradient {
    background: linear-gradient(to right, #c7b2d6, #5ec4b6);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}