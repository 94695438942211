@media (max-width: 480px) {
    .product-section {
        height: fit-content;
        padding: 60px 0;
    }

    .product-container {
        display: grid;
        grid-template-columns: 1fr;
        gap: 16px;
        width: 78%;
    }

    .product-card {
        height: 280px;
    }

    .product-img {
        width: 100%;
        height: 230px;
    }

    .product-name {
        font-size: 18px;
    }

    .product-function {
        font-size: 16px;
    }
}

@media (min-width: 481px) and (max-width: 768px) {
    .product-section {
        height: fit-content;
        padding: 60px 0;
    }

    .product-container {
        display: grid;
        grid-template-columns: 1fr 1fr;
        /* Two equal columns */
        grid-template-rows: auto;
        /* Adjust rows to fit content automatically */
        gap: 16px;
        width: 88%;
    }

    .product-card {
        height: 280px;
    }

    .product-img {
        width: 100%;
        height: 230px;
    }

    .product-name {
        font-size: 18px;
    }

    .product-function {
        font-size: 16px;
    }

}

@media (min-width: 769px) and (max-width: 1200px) {
    .product-section {
        height: fit-content;
        padding: 60px 0;
    }

    .product-container {
        display: grid;
        grid-template-columns: 1fr 1fr;
        /* Two equal columns */
        grid-template-rows: auto;
        /* Adjust rows to fit content automatically */
        gap: 16px;
        width: 88%;
    }

    .product-card {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}