.banner{
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: sticky;
    top: 0;
    z-index: 2;
    background-color: var(--dark-green);
}

.banner-text{
    font-family: Arial, Helvetica, sans-serif;
    font-size: 16px;
    font-weight: 500;
    color: white;
    letter-spacing: 0.5px;
}

.whatsapp-link{
    color: white;
}

.whatsapp-link:hover{
    color: rgba(255, 255, 255, 0.7);
}