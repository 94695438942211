@media (max-width: 768px) {
    .hero-section {
        padding: 60px 0;
    }

    .hero-content {
        flex-direction: column;
        text-align: left;
    }

    .hero-text {
        padding-right: 0;
        margin-bottom: 40px;
    }

    .wlc-msg h1 {
        font-size: 36px;
    }

    .hero-text p {
        font-size: 17px;
        margin: 24px 0;
    }
}

@media (min-width: 481px) and (max-width: 768px) {
    .hero-text {
        width: 90%;
    }
}

@media (min-width: 769px) and (max-width: 1200px) {
    .hero-section {
        padding: 60px 5%;
    }
}