/* General Styles */
body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
}

.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
}

/* free Section Styles */
.free-section {
    background-color: var(--white-background);
    padding: 80px 0;
}

.free-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.free-text {
    flex: 1;
    padding-left: 40px;
}

.free-bullet {
    display: block;
    justify-content: start;
    margin-bottom: 36px;
}

.bullet-text {
    display: flex;
    justify-content: start;
    align-items: center;
    font-size: 17px;
    color: #666;
    line-height: 1.6;
    margin-bottom: 16px;
}

.gift-icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: flex-start;
    margin-right: 12px;
}

.gift-icon {
    margin-top: 2px;
    font-size: 22px;
    color: var(--dark-green-hover);
}

.free-image {
    display: flex;
    justify-content: start;
    align-items: center;
    flex: 1;
}

.free-image img {
    max-width: 100%;
    height: auto;
    max-height: 500px;
    border-radius: 8px;
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
}