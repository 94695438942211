:root {
    --white-background: #f8f8f8;
    --grey-text: #666;
    --light-green: #5ec4b6;
    --light-green-background: #e0f0ee;
    --dark-green: #36a393;
    --dark-green-hover: #2b8578;
    --light-purple: #c7b2d6;
    --dark-purple: #665f9e;
    --dark-purple-hover: #544d81;
    --light-blue: #3c9cd7;
    --dark-blue: #0c5b88;
}

.section-title {
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 1px;
    margin-bottom: 12px;
    margin-left: 0.3%;
    color: var(--dark-green-hover);
}

.section-header {
    display: flex;
    justify-content: start;
    align-items: center;
    height: fit-content;
    margin-bottom: 12px;
}

.section-header span {
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    font-size: 40px;
    font-weight: bold;
}

.section-subheader {
    font-size: 17px;
    color: #666;
    margin-bottom: 36px;
}

@media (max-width: 768px) {
    .section-title {
        font-size: 18px;
        text-align: left;
    }

    .section-header span {
        font-size: 28px;
    }

    .section-subheader{
        line-height: 1.6;
        text-align: left;
    }
}